.estimate-item-card{
  padding: 25px !important;
  padding-bottom: 15px !important
}


@font-face {
  font-family: 'TomatoGrotesk';
  src: url('../theme/font/TomatoGrotesk-Regular.woff') format('woff'),
    url('../theme/font/TomatoGrotesk-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'TomatoGrotesk500';
  src: url('../theme/font/TomatoGrotesk-Regular.woff') format('woff'),
    url('../theme/font/TomatoGrotesk-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'TomatoGroteskbold';
  src: url('../theme/font/AnyConv.com__TomatoGrotesk-Bold.woff') format('woff'),
    url('../theme/font/TomatoGrotesk-Bold.woff2') format('woff2');
}
* {
  font-family: 'TomatoGrotesk' !important;
}
:root {
  // --primary-color: #ef4923;
  --primary-color: #ec2927;
  --secondary-color: #ffefec;
  --grey-color: #959595;
}
// searching
/* Target the input element with the "search-input" class */
.search-input {
  border: none; /* Remove the default border */
  outline: none; /* Remove the outline when focused */
}
.search-btn {
  border: none;
  background-color: transparent;
}
/* Optionally, you can add some additional styling to make it visually appealing */
.search-input:focus {
  border: 1px solid #ccc; /* Add a border when the input is focused */
  border-radius: 4px; /* Add rounded corners */
}
//products page
.pductsstyleroot {
  background-size: cover;
  background-size: cover;
  background-position: center;
  height: 219px;
  // background-color: var(--primary-color);
  // opacity: .5,
}
// common
.zeal-btn-solid {
  background-color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #be302f;
  }
}
.btn404 {
  background-color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #be302f;
  }
}
.zeal-btn-outline {
  background-color: white;
  border: 1px solid var(--primary-color);
  color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #ffdad2;
    border: 1px solid rgba(236, 41, 39, 255);
    color: rgba(236, 41, 39, 255);
    .maingrid {
      position: relative;
      .childgrid {
        top: 7.5rem;
        position: absolute;
        left: 7rem;
      }
    }
  }
}
.popupbtn {
  background-color: rgba(236, 41, 39, 255);
  &:hover {
    background-color: #be302f;
  }
  width: 114px;

  letter-spacing: 1px;
}
.btndiv {
  display: flex;
  justify-content: center;
}

// section 2 style
.Transformmain-typo {
  text-align: center;
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 2rem;
  line-height: 28px;
}
.CardTransform {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--secondary-color);
  box-shadow: unset;
  align-items: center;
  // width: 250px;

  .card-img {
    // height: 134px;
    // width: 114px;
    object-fit: contain;
  }

  .Card-content {
    // margin: 3px;
    padding: 8px;
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .card-content {
    font-size: 24px;
    color: var(--grey-color);
    font-weight: 900 !important;
    text-align: center;
  }
}
.wrapper {
  word-wrap: break-word;
}
.custom-textfield input {
  font-size: 15px;
}
.custom-textfield .MuiInputBase-input {
  color: #d31412;
}

.custom-textfield .MuiOutlinedInput-root {
  border-radius: 0;
  // background-color: #c23b1c;
  // background-color: #d31412;
  border: 1px solid #d31412;
}

.custom-textfield .MuiOutlinedInput-root fieldset {
  border: none;
}

.custom-textfield ::placeholder {
  color: var(--grey-color);
}

.custom-textfield-booking input {
  font-size: 15px;
}
.custom-textfield-booking .MuiInputBase-input {
  color: #090909;
}

.custom-textfield-booking .MuiOutlinedInput-root {
  border-radius: 0;
  background-color: var(--secondary-color);
}

.custom-textfield-booking .MuiOutlinedInput-root fieldset {
  border: 2px solid var(--primary-color);
}

.custom-textfield-booking .MuiOutlinedInput-root :hover {
  border: var(--primary-color);
}

.custom-textfield-booking ::placeholder {
  color: var(--grey-color);
}

.custom-textfield-feedback input {
  font-size: 15px;
}
.custom-textfield-feedback .MuiInputBase-input {
  color: #090909;
}

.custom-textfield-feedback .MuiOutlinedInput-root {
  border-radius: 0;
  background-color: var(--secondary-color);
}

.custom-textfield-feedback .MuiOutlinedInput-root fieldset {
  border: 1px solid var(--primary-color);
}

.custom-textfield-feedback .MuiOutlinedInput-root :hover {
  border: var(--primary-color);
}

.custom-textfield-feedback ::placeholder {
  color: var(--grey-color);
}

.custom-inputlabel {
  color: #5f5c5c;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 5px;
}

.lngpgicon {
  margin-right: 8px;
}
#sidebar-inActive {
  transition: all ease-in-out 0.3s;

  width: 65px;

  .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
    width: 65px;
  }
  .css-121b4uz-MuiListItemIcon-root {
    min-width: 48px;
  }
}

#sidebar-active {
  transition: all ease-in-out 0.3s;
  width: 280px;
  .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
    width: 280px;
  }
}
.logo {
  width: 300px;
  height: 80px;
  object-fit: contain;
}
.right-column {
  width: 50% !important;
}

.lngbtnmain {
  font-size: 16px;
}
.gridsun {
  margin-top: 5rem;
}
.boldfont {
  font-family: 'TomatoGroteskbold' !important;
}
.link {
  color: black;
}
.samesize {
  width: 261px;
  height: 261px;
}
.map-pointer {
  cursor: pointer !important;
}
.emojibtn {
  background: none !important;
  border-radius: 25px !important;
  padding: 0 !important;
}
.dnone {
  display: none;
}
.RighrArrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.LeftArrow {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
// mediaquery
@media (max-width: 1024px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 10px !important;
  }
  .lngbtnmain {
    font-size: 13px;
  }
  .left_content_container {
    width: 500px !important;
    top: 30px !important;
    left: 60px;
    padding: 5px 45px;
  }
  .logo {
    width: 149px;
    height: 47px;
  }
}
@media (max-width: 769px) {
  .dnone {
    display: flex;
  }
  .headingh1 {
    margin-top: 1rem;
  }
  .right-column {
    width: 75% !important;
  }
  .contntbox {
    width: 100% !important;
  }
  .imagebox {
    width: 100% !important;
    // padding-top: 12px;
  }
  .left_content_container {
    position: unset !important;
    width: 100% !important;
    padding-left: 39px !important;
  }
  .imagecontainer {
    position: unset !important;
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .formsection {
    width: 337px !important;
  }
  .left-column {
    max-width: unset !important;
  }
  .left-column {
    margin-left: 0rem !important;
  }
  .quickimg {
    max-width: 722px !important;
  }
  .bookbutton {
    margin-bottom: 2rem !important;
  }
  .spleft {
    margin-top: 9rem !important;
  }
}
@media (max-width: 414px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 9px !important;
  }
}
@media (max-width: 426px) {
  .hidebtn1 {
    display: none;
  }

  .marBotm {
    margin-bottom: 35px;
  }
  .CardTransform {
    .Card-content {
      padding: 0px !important;
    }
  }

  .jobdetails {
    width: 60%;
  }
  .maindivrow {
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .gridsun {
    margin-top: 0px;
  }
  .btnsize {
    font-size: 13px;
  }
  .abtpadding {
    padding: 1rem;
  }
  .EstimateContainer {
    .css-1yxm0zd-MuiPaper-root-MuiCard-root {
      margin: 10px;
      box-shadow: none;
    }
  }
  .headingh1 {
    margin-top: 0rem;
  }
  .css-v5sfno-MuiPaper-root-MuiCard-root {
    margin-left: 18px;
    margin-right: 18px;
  }
  .bookbutton {
    font-size: 11px;
    width: 176px;
  }
  .lngbtn {
    font-size: 12px;
    // width: 226px !important;
    width: auto;
  }
  .lngbtnmain {
    // font-size: 11px;
    width: auto;
  }
  .left_content_container {
    padding: 23px 18px;
  }
  .lngpgicon {
    // margin-right: 3px;
  }
  .right-column {
    width: 100% !important;
  }
  .left-column {
    width: 100% !important;
  }
  .left-column {
    width: 100% !important;
  }
  .rptop {
    margin-top: 2rem;
  }
  // .mbbtn {
  //   width: 153px !important;
  // }
  .custom-inputlabel {
    font-size: 12px;
  }
  .custom-textfield input {
    font-size: 12px;
  }
  .css-13isf56-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 10px;
  }
  .request-btn {
    font-size: 18px;
  }

  .footerlogo {
    opacity: 0.3;
  }
  .spleft {
    margin-top: 0rem !important;
  }
  .left-column {
    padding-left: 18px !important;
    padding-right: 15px !important;
  }
  .gridsun {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .bookbutton {
    margin-bottom: unset !important;
  }
  .quickimg {
    max-width: 580px !important;
  }
  .landgmainsection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px;
  }
  .Card-content {
    margin: 0px;
    padding: 0px;
    margin-right: 6rem !important;
    margin-left: 6rem !important;
  }
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 10px !important;
  }
  .logo {
    width: 164px;
    height: 37px;
  }
  .Transformmain-typo {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .card-content {
    font-size: 18px !important;
  }

  .zcareimg {
    width: 52px !important;
    height: 60px !important;
  }
  .transparentdiv {
    width: 113px !important;
    height: 113px !important;
    // top:28% !important;
  }
  .contentsection {
    padding: 2rem 1.5rem !important;
  }
  .formsection {
    width: 394px !important;
    padding-top: 0rem !important;
  }
}

@media (max-width: 384px) {
  .lngbtn {
    font-size: 9px !important;
  }
}
@media (max-width: 358px) {
  .hidebtn {
    display: none;
  }
}
@media (max-width: 400px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 9px !important;
  }
}
@media (max-width: 345px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 7px !important;
  }
}
@media (max-width: 390px) {
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 8px !important;
  }
  .lngbtnmain {
    // font-size: 9px;
  }
  .formsection {
    // width: 343px !important;
  }
  .logo {
    width: 122px;
    height: 37px;
  }
}
@media (max-width: 321px) {
  .jobdetails {
    width: 55%;
    margin-right: 1rem;
  }
  .zeal-btn-solid {
    font-size: 10px;
  }
  .mbbtn {
    font-size: 13px;
  }
  .lngbtn {
    font-size: 10px;
  }
  .select {
    font-size: 10px;
  }
  .click,
  .book {
    font-size: 14px;
  }
  .footerlogo {
    height: 72px !important;
    width: 156px;
  }
  .css-1dshbck-MuiButtonBase-root-MuiButton-root {
    font-size: 6px !important;
  }
  .lngbtnmain {
    // font-size: 6px;
  }
  .formsection {
    width: 287px !important;
  }
  .lngpgicon {
    margin-right: 11px;
  }
  .lngbtn {
    text-align: center;
  }
}
// #main-body-sidebar-active {
// }
#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.session-table {
  td {
    vertical-align: top;
  }
}

.product-measurement-input {
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 0;
  }
  .css-znbc8-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input {
    color: var(--grey-color);
  }
}

.my-DateCalendar {
  // live
  .MuiPickersDay-dayWithMargin, // other dates
  .MuiPickersDay-dayWithMargin.Mui-selected, // selected date
  .MuiPickersDay-dayWithMargin.Mui-disabled,//  disabled dates
  .MuiPickersDay-hiddenDaySpacingFiller,// empty date
  .MuiDayCalendar-weekDayLabel, // week label
  // dev
  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root, // selected date
  .css-ka6agc-MuiButtonBase-root-MuiPickersDay-root, // other dates and disabled
  .css-1602a6u-MuiPickersDay-root, // empty date
  .css-1n0erti-MuiTypography-root-MuiDayCalendar-weekDayLabel // week label
  {
    font-size: 15px !important;
    width: 60px !important;
    height: 60px !important;
    @media (max-width: 500px) {
      font-size: 13px !important;
      width: 35px !important;
      height: 35px !important;
    }
  }

  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root:hover,
  .MuiPickersDay-dayWithMargin:hover,
  .css-ka6agc-MuiButtonBase-root-MuiPickersDay-root:hover {
    background-color: #ffd4cc !important;
  }
  //
  .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition,
  .css-1cafy48-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition {
    // date container
    min-height: 400px !important;
  }
  .MuiDateCalendar-root,
  .css-1r8uv9g-MuiDateCalendar-root {
    max-height: 520px !important;
  }
  .MuiPickersDay-dayWithMargin.Mui-selected,
  .css-12l9xv4-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: var(--primary-color) !important;
  }
  .MuiPickersCalendarHeader-root,
  .css-nk89i7-MuiPickersCalendarHeader-root {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-left: 65px;
    padding-right: 40px;
    @media (max-width: 500px) {
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 70px;
      padding-right: 45px;
    }
  }
}

.floating-card {
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.5s;
  bottom: 0px;
  &.active {
    opacity: 1;
    visibility: visible;
    bottom: 76px;
  }
}

.custom-textfield-booking {
  &.location {
    .MuiInputBase-root {
      padding-top: 14px !important;
      padding-bottom: 14px !important;
      padding-left: 6px;
    }
    .css-12vka4q-MuiFormLabel-root-MuiInputLabel-root {
      top: 7px !important;
    }
  }
}

.search_map {
  position: absolute;
  top: 10px;
  left: 220px;
  width: 100%;
  max-width: 400px;
  z-index: 10;
  @media (max-width: 500px) {
    position: absolute;
    top: 60px;
    left: 5px;
    width: 100%;
    max-width: 400px;
    z-index: 10;
  }
}
.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border: none;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  // border-radius: 5px;
}
.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 35px;
  width: 40px;
  border-radius: 5px;
}
.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}
